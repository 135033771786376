import React from "react";
import StatusCodes from "http-status-codes";
import { useTranslation } from "react-i18next";

const ErrorHandler = (props) => {
  const { t } = useTranslation();
  let message;

  switch (props.error.status) {
    case StatusCodes.UNAUTHORIZED:
      if (props.header) {
        message = "error.error_unautorized";
      } else {
        message = "error.error_credentials";
      }
      break;
    case StatusCodes.FORBIDDEN:
      message = "error.error_forbidden";
      break;
    case StatusCodes.NOT_FOUND:
      message = "error.error_not_found";
      break;
    case StatusCodes.UNPROCESSABLE_ENTITY:
      message = "error.error_unprocessable_entity";
      break;
    default:
      message = "error.error_default";
  }

  return props.header ? (
    <div>
      <h3>{t("error.error_header")}</h3>
      {t(message)}
    </div>
  ) : (
    <div className="text-center alert alert-danger" role="alert">
      {t(message)}
    </div>
  );
};

export default ErrorHandler;
