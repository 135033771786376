import React from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div>
      <nav className="navbar navbar-light bg-light fixed-bottom">
        <small className="text-black-50 ml-5">
          &copy; 2020 - {new Date().getFullYear()}{" "}
          <a href="https://usource.com.ua">Usource Team</a>
        </small>
        <small className="text-black-50 ml-5">
          {t("main.send_us")}
          <Mailto email="usourceteam@gmail.com">usourceteam@gmail.com</Mailto>
        </small>
        <div className="navbar-expand ml-auto nav mr-5 font-weight-bold">
          <Link to={"/about"} className="nav-link">
            {t("main.about")}
          </Link>
        </div>
      </nav>
    </div>
  );
};

const Mailto = ({ email, subject = "", body = "", children }) => {
  let params = subject || body ? "?" : "";
  if (subject) params += `subject=${encodeURIComponent(subject)}`;
  if (body) params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;

  return <a href={`mailto:${email}${params}`}>{children}</a>;
};

export default Footer;
