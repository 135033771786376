import React from "react";

import promo from "../img/background.jpg";
import { useTranslation } from "react-i18next";
import Footer from "./footer.component";

const Home = () => {
  const { t } = useTranslation();

  return (
    <div className="container-fluid min-vh-100">
      <div className="text-center vertical">
        <div className="h1 text-primary mt-1">
          <b className="d-inline-block">Usource</b>
          {t("main.promo_1")}
        </div>
        <div className="h5 mt-5">{t("main.promo_2")}</div>
        <div className="text-center vertical">
          <img src={promo} className="img-responsive" alt=""/>
        </div>
      </div>
      <div className="text-right">
        <small>
          <a className="text-black-50 mr-5" href="http://www.freepik.com">
            Image designed by pch.vector / Freepik
          </a>
        </small>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
