import axiosService from "./axios.service";

const getStatus = () => {
  return axiosService.get(
    `/actuator/health`
  );
};

const HealthCheckService = {
  getStatus,
};

export default HealthCheckService;
