import axiosService from "../services/axios.service";
import LocalStorageService from "../services/local-storage.service";

const getSections = () => {
  return axiosService.get(
    `/api/v1/content/sections?lng=${LocalStorageService.userLanguage()}`,
    { headers: LocalStorageService.authHeader() }
  );
};

const getContents = () => {
  return axiosService.get(
    `/api/v1/content/${LocalStorageService.currentSection()}/contents`,
    { headers: LocalStorageService.authHeader() }
  );
};

const getChapter = (number) => {
  return axiosService.get(
    `/api/v1/content/${LocalStorageService.currentSection()}/chapter/${number}`,
    { headers: LocalStorageService.authHeader() }
  );
};

const ContentService = {
  getSections,
  getContents,
  getChapter,
};

export default ContentService;
