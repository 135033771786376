import React, { useState, useEffect } from "react";
import { Switch, Route, Link } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import LocalStorageService from "./services/local-storage.service";
import ContentService from "./services/content.service";

import Login from "./components/login.component";
import Register from "./components/register.component";
import Section from "./components/section.component";
import Home from "./components/home.component";
import About from "./components/about.component";
import Profile from "./components/profile.component";
import ChangePassword from "./components/change-password.component";
import BoardAdmin from "./components/board-admin.component";
import LanguageSwitcher from "./components/language-switcher";
import HealthCheck from "./components/health-check.component";

import Dropdown from "react-bootstrap/Dropdown";
import "./i18n";
import { useTranslation } from "react-i18next";

const App = () => {
  const [sections, setSections] = useState([]);
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [currentUser, setCurrentUser] = useState(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    const user = LocalStorageService.currentUser();

    if (user) {
      setCurrentUser(user);
      setShowAdminBoard(user.roles.some((item) => item.name === "ROLE_ADMIN"));
    }

    ContentService.getSections().then(
      (response) => {
        setSections(response.data);
      }
    );
  }, []);

  const logOut = () => {
    LocalStorageService.logout();
  };

  return (
    <div>
      <nav className="navbar navbar-expand sticky-top navbar-dark bg-dark font-weight-bold">
        <Link to={"/"} className="navbar-brand">
          Usource
        </Link>
        <SectionsList sections={sections} />

        {currentUser ? (
          <div className="navbar-nav ml-auto">
            <Dropdown>
              <Dropdown.Toggle
                className="nav-link"
                variant="none"
                id="userMenu"
              >
                {currentUser.username}
              </Dropdown.Toggle>

              <Dropdown.Menu align="right">
                <Dropdown.Item href="/profile">
                  {t("main.profile")}
                </Dropdown.Item>
                <Dropdown.Item href="/password">
                  {t("main.change_password")}
                </Dropdown.Item>
                {showAdminBoard && <Dropdown.Divider />}
                {showAdminBoard && (
                  <Dropdown.Item href="/admin">{t("main.admin")}</Dropdown.Item>
                )}
                <Dropdown.Divider />
                <Dropdown.Item
                  href="/login"
                  className="dropdown-item"
                  onClick={logOut}
                >
                  {t("main.logout")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ) : (
          <div className="navbar-nav ml-auto">
            <li className="nav-link">
              <LanguageSwitcher />
            </li>
            <li className="nav-item">
              <Link to={"/login"} className="nav-link">
                {t("main.login")}
              </Link>
            </li>

            <li className="nav-item">
              <Link to={"/register"} className="nav-link">
                {t("main.sign_up")}
              </Link>
            </li>
          </div>
        )}
      </nav>

      <div className="container-fluit pt-0 pl-3 pr-3 pb-0">
        <Switch>
          <Route exact path={["/", "/home"]} component={Home} />
          <Route exact path={["/about"]} component={About} />
          <Route exact path={["/section/:section_id/chapter/:chapter_id"]} component={Section} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path={["/profile", "/user"]} component={Profile} />
          <Route path="/password" component={ChangePassword} />
          <Route path="/admin" component={BoardAdmin} />
          <Route path="/health" component={HealthCheck} />
        </Switch>
      </div>
    </div>
  );
};

function SectionsList({ sections }) {
  const listItems =
    sections != null
      ? sections.map((item) => (
          <div key={item.id}>
            <Link
              to={`/section/${item.id}/chapter/${LocalStorageService.currentChapter(item.id)}`}
              className="nav-link"
              disabled={item.demo}
              onClick={() => localStorage.setItem("currentSection", item.id)}
            >
              {item.name}
            </Link>
          </div>
        ))
      : null;
  return <div className="navbar-nav">{listItems}</div>;
}

export default App;
