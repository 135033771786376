import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    supportedLngs: ["ukr", "eng", "tst", "rus"],
    nonExplicitSupportedLngs: true,
    backend: {
      loadPath: '/translations/{{lng}}.json'
    },
    fallbackLng: 'eng',
    debug: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
    react: {
      wait: true
    }
  })

export default i18n;