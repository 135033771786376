import axios from "axios";
import StatusCodes from "http-status-codes";
import LocalStorageService from "../services/local-storage.service";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST + ":" + process.env.REACT_APP_API_PORT + "/usource",
});

instance.interceptors.response.use(
  function (response) {
    return response;
  }, function (error) {
    if (error.response.status === StatusCodes.UNAUTHORIZED && LocalStorageService.currentUser() != null) {
      LocalStorageService.logout();
      window.location = '/login';
    } 
    return Promise.reject(error);
  });

export default instance;
