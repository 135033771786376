import React, { useState, useRef } from "react";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { useTranslation } from "react-i18next";

import UserService from "../services/user.service";
import ErrorHandler from "../components/error-handler.component";
import { Link } from "react-router-dom";

const ChangePassword = () => {
  const form = useRef();
  const checkBtn = useRef();
  const { t } = useTranslation();

  const [passwordCurrent, setPasswordCurrent] = useState("");
  const [passwordNew, setPasswordNew] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const required = (value) => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          {t("error.field_required")}
        </div>
      );
    }
  };

  const vpassword = (value) => {
    //Minimum 8 and maximum 32 characters, at least one uppercase letter, one lowercase letter, one number and one special character
    const reg = new RegExp(
      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!%+<>@$%^&*_`~]).{8,32}$"
    );
    if (!reg.test(value)) {
      return (
        <div className="alert alert-danger" role="alert">
          {t("error.field_password_length")}
        </div>
      );
    }
  };

  const onChangePasswordCurrent = (e) => {
    const passwordCurrent = e.target.value;
    setPasswordCurrent(passwordCurrent);
    setError("");
    setMessage("");
  };

  const onChangePasswordNew = (e) => {
    const passwordNew = e.target.value;
    setPasswordNew(passwordNew);
    setError("");
    setMessage("");
  };

  const onChangePasswordConfirm = (e) => {
    const passwordConfirm = e.target.value;
    setPasswordConfirm(passwordConfirm);
    setError("");
    setMessage("");
  };

  const handleChangePassword = (e) => {
    e.preventDefault();

    setError("");
    setLoading(true);
    setDone(false);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      if (passwordNew !== passwordConfirm) {
        setMessage(t("error.field_password_equal"));
      } else {
        UserService.changePassword(passwordCurrent, passwordNew).then(
          () => {
            setError("");
            setDone(true);
          },
          (error) => {
            setDone(false);
            setError(error.response);
          }
        );
      }
    }
    setLoading(false);
  };

  return (
    <div className="col-md-12">
      <div className="card card-container">
        <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        />

        <Form onSubmit={handleChangePassword} ref={form}>
          {done ? (
            <div className="form-group">
              <div className="text-center alert alert-success" role="alert">
                <b>{t("password.message_success")}</b>
                <p>
                  <p />
                  {t("password.message_success_start")}
                  <Link to={"/"} className="btn-link">
                    {t("password.message_success_link")}
                  </Link>
                  {t("password.message_success_end")}
                </p>
              </div>
            </div>
          ) : (
            <div>
              <div className="form-group">
                <label htmlFor="passwordCurrent">
                  {t("password.password_current")}
                </label>
                <Input
                  type="password"
                  className="form-control"
                  name="passwordCurrent"
                  value={passwordCurrent}
                  onChange={onChangePasswordCurrent}
                  validations={[required]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="passwordNew">
                  {t("password.password_new")}
                </label>
                <Input
                  type="password"
                  className="form-control"
                  name="passwordNew"
                  value={passwordNew}
                  onChange={onChangePasswordNew}
                  validations={[required, vpassword]}
                />

                <div className="notes">
                  <div>{t("password.password_info_1")}</div>
                  <div>{t("password.password_info_2")}</div>
                  <div>{t("password.password_info_3")}</div>
                  <div>{t("password.password_info_4")}</div>
                  <div>{t("password.password_info_5")}</div>
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="passwordConfirm">
                  {t("password.password_confirm")}
                </label>
                <Input
                  type="password"
                  className="form-control"
                  name="passwordConfirm"
                  value={passwordConfirm}
                  onChange={onChangePasswordConfirm}
                  validations={[required, vpassword]}
                />
              </div>

              <div className="form-group">
                <button
                  className="btn btn-primary btn-block"
                  disabled={loading}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>{t("password.button")}</span>
                </button>
              </div>

              {message && !error && (
                <div className="form-group">
                  <div className="text-center alert alert-danger" role="alert">
                    {message}
                  </div>
                </div>
              )}

              {error && !message && (
                <div className="form-group">
                  <ErrorHandler error={error} header={false} />
                </div>
              )}
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </div>
  );
};

export default ChangePassword;
