import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import ContentService from "../services/content.service";
import LocalStorageService from "../services/local-storage.service";
import ErrorHandler from "../components/error-handler.component";

const Section = ({ match }) => {
  const [contents, setContents] = useState([]);
  const [chapter, setChapter] = useState("");
  const [error, setError] = useState("");

  const fetchContent = () => {
    ContentService.getContents().then(
      (response) => {
        setContents(response.data);
      },
      (error) => {
        const _contents =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        setContents(_contents);
      }
    );
  };

  const fetchChapter = (sectionNumber, chapterNumber) => {
    ContentService.getChapter(chapterNumber).then(
      (response) => {
        setChapter(response.data.response);
        setError("");
        localStorage.setItem("currentChapter_" + sectionNumber, chapterNumber);
      },
      (error) => {
        setChapter("");
        setError((error.response && error.response.data) || error.message || error.toString());
      }
    );
  };

  useEffect(() => {
    fetchContent();
    fetchChapter(match.params.section_id, match.params.chapter_id);
  }, []);

  return (
    <div className="row">
      <div className="col-md-3 bg-light pt-3 vh-100">
        <ContentsTable contents={contents} currentSection={match.params.section_id} currentChapter={match.params.chapter_id} chapterSelected={fetchChapter} />
      </div>
      <div className="col-md-9 text-justify pt-3">
        {chapter ? (
          <div dangerouslySetInnerHTML={{ __html: ManageApiHost(chapter) }}></div>
        ) : (
          <ErrorHandler error={error} header={true} />
        )}
      </div>
    </div>
  );
}

function ContentsTable({ contents, currentSection, currentChapter, chapterSelected }) {
  const listItems =
    contents != null
      ? contents.map((item) => (
        <div key={item.chapter}>
          <Link
            to={`/section/${LocalStorageService.currentSection()}/chapter/${item.chapter}`}
            className={"btn btn-link text-left " + (item.chapter === currentChapter ? "font-weight-bold" : "")}
            disabled={item.demo}
            onClick={() => chapterSelected(currentSection, item.chapter)}
          >
            {`${item.chapter} ${item.title}`}
          </Link>
        </div>
      ))
      : null;
  return (
    <div className="btn-group-vertical" role="group">
      {listItems}
    </div>
  );
}

const ManageApiHost = (htmlBlock) => {
  return htmlBlock.replace(/(http|https):\/\/localhost:8080/gi, process.env.REACT_APP_API_HOST + ':' + process.env.REACT_APP_API_PORT);
}

export default Section;
