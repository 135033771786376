import React, { useState, useEffect } from "react";

import HealthCheckService from "../services/health-check.service";
import StatusCodes from "http-status-codes";

const HealthCheck = () => {
  const [status, setStatus] = useState("");

  const fetchStatus = () => {
    HealthCheckService.getStatus().then(
      (response) => {
        setStatus(response);
      }
    );
  };

  useEffect(() => {
    fetchStatus();
  }, []);

  return (
    <div>
      <b>Health check status details:</b>
      {status.status === StatusCodes.OK ? 
        <ul>
          <StatusItem id="generalStatus" label="General Status" status={status.data.status} />
          <StatusItem id="dbStatus" label="Data Base Status" status={status.data.components.db.status} />
          <StatusItem id="diskSpaceStatus" label="Disk Space Status" status={status.data.components.diskSpace.status} />
          <StatusItem id="pingStatus" label="Ping Status" status={status.data.components.ping.status} />
        </ul>
      : status.data
      }
    </div>
  );
}

const getStatusStyle = (status) => ({
  color: status === 'UP' ? 'green' : 'red',
});

const StatusItem = ({ id, label, status }) => (
  <li id={id}>
    {label}:&nbsp; 
    <b style={getStatusStyle(status)}>{status}</b>
  </li>
);

export default HealthCheck;
