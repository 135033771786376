import React from "react";
import { useTranslation } from "react-i18next";
import LocalStorageService from "../services/local-storage.service";

function LanguageSwitcher() {

  const { i18n } = useTranslation();

  const onChangeLanguage = (e) => {
    i18n.changeLanguage(e.target.value);
    window.location.reload();
  };

  return (
    <div>
      <select
        className="btn-secondary btn-sm"
        value={LocalStorageService.userLanguage().toString().toLowerCase()}
        onChange={onChangeLanguage}
      >
        {global.config.languages.map((item) => (
          <option key={item.value} value={item.value.toString().toLowerCase()}>
            {item.label.toString().substring(0, 3).toUpperCase()}
          </option>
        ))}
      </select>
    </div>
  );
}

export default LanguageSwitcher;
