import i18n from "../i18n";

const authHeader = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  if (user && user.token) {
    return { Authorization: "Bearer " + user.token };
  } else {
    return {};
  }
};

const userLanguage = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  if (user && user.lng) {
    return user.lng;
  } else {
    return i18n.language.toString().toUpperCase();
  }
};

const currentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const currentSection = () => {
  let currentSection = localStorage.getItem("currentSection");
  if (currentSection === null) {
    currentSection = "1";
    localStorage.setItem("currentSection", currentSection);
  }
  return currentSection;
};

const currentChapter = (section_id) => {
  let currentChapter = localStorage.getItem("currentChapter_" + section_id);
  if (currentChapter === null) {
    currentChapter = "0";
    localStorage.setItem("currentChapter_" + section_id, currentChapter);
  }
  return currentChapter;
};

const logout = () => {
  localStorage.removeItem("user");
};

const LocalStorageService = {
  authHeader,
  userLanguage,
  currentUser,
  currentSection,
  currentChapter,
  logout,
};

export default LocalStorageService;
