import React from "react";

import { useTranslation } from "react-i18next";
import logo from "../img/usource_large_logo_compressed.png";
import Footer from "./footer.component";

const About = () => {
  const { t } = useTranslation();

  return (
    <div className="container-fluid min-vh-100">
      <div className="text-center vertical">
        <img src={logo} className="img-responsive" alt=""/>
        <div className="mt-5 text-left">
          <p>{t("about.greeting")}</p>
          <p className="text-justify">
          {t("about.text")}
          </p>
          <p>{t("about.ending")}</p>
          <p>{t("about.signature")}</p>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default About;
