import axiosService from "../services/axios.service";
import LocalStorageService from "../services/local-storage.service";

const login = (username, password) => {
  return axiosService
    .post("/api/v1/user/sign_in", { username, password })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const register = (username, password, firstName, lastName, avatar, lng) => {
  return axiosService.post("/api/v1/user/sign_up", {
    username,
    password,
    firstName,
    lastName,
    avatar,
    lng,
  });
};

const getUser = () => {
  return axiosService.get("/api/v1/user/get_user", {
    headers: LocalStorageService.authHeader(),
  }).then((response) => {
    if (response.data.token) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }

    return response.data;
  });
};

const updateUser = (username, firstName, lastName, avatar, lng) => {
  return axiosService.put(
    "/api/v1/user/update_user",
    {
      username,
      firstName,
      lastName,
      avatar,
      lng,
    },
    {
      headers: LocalStorageService.authHeader(),
    }
  ).then((response) => {
    if (response.data.token) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }

    return response.data;
  });
};

const changePassword = (current, neo) => {
  return axiosService.post(
    "/api/v1/user/change_password",
    {
      current,
      neo,
    },
    {
      headers: LocalStorageService.authHeader(),
    }
  ).then((response) => {
    if (response.data.token) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }

    return response.data;
  });
};

const UserService = {
  login,
  register,
  getUser,
  updateUser,
  changePassword,
};

export default UserService;
