import React, { useState, useRef } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import Select from "react-select";
import { isEmail } from "validator";
import { Link } from "react-router-dom";

import UserService from "../services/user.service";
import { useTranslation } from "react-i18next";
import LocalStorageService from "../services/local-storage.service";
import ErrorHandler from "../components/error-handler.component";

const Register = (props) => {
  const form = useRef();
  const checkBtn = useRef();
  const { t } = useTranslation();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [avatar] = useState("");
  const [language, setLanguage] = useState(
    global.config.languages.find(
      (item) =>
        item.value ===
        LocalStorageService.userLanguage().toString().toUpperCase()
    )
  );
  const [done, setDone] = useState(false);
  const [error, setError] = useState("");

  const required = (value) => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          {t("error.field_required")}
        </div>
      );
    }
  };

  const email = (value) => {
    if (!isEmail(value)) {
      return (
        <div className="alert alert-danger" role="alert">
          {t("error.field_invalid_email")}
        </div>
      );
    }
  };

  const vusername = (value) => {
    if (value.length < 3 || value.length > 20) {
      return (
        <div className="alert alert-danger" role="alert">
          {t("error.field_username_length")}
        </div>
      );
    }
  };

  const vpassword = (value) => {
    //Minimum 8 and maximum 32 characters, at least one uppercase letter, one lowercase letter, one number and one special character
    const reg = new RegExp(
      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!%+<>@$%^&*_`~]).{8,32}$"
    );
    if (!reg.test(value)) {
      return (
        <div className="alert alert-danger" role="alert">
          {t("error.field_password_length")}
        </div>
      );
    }
  };

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const onChangeFirstName = (e) => {
    const firstName = e.target.value;
    setFirstName(firstName);
  };

  const onChangeLastName = (e) => {
    const lastName = e.target.value;
    setLastName(lastName);
  };

  const onChangeLanguage = (e) => {
    const language = e;
    setLanguage(language);
  };

  const handleRegister = (e) => {
    e.preventDefault();

    setDone(false);
    setError("");

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      UserService.register(
        username,
        password,
        firstName,
        lastName,
        avatar,
        language.value
      ).then(
        (response) => {
          setDone(true);
          setError("");
        },
        (error) => {
          setError(error.response);
          setDone(false);
        }
      );
    }
  };

  return (
    <div className="col-md-12">
      <div className="card card-container">
        <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        />

        <Form onSubmit={handleRegister} ref={form}>
          {done ? (
            <div className="form-group">
              <div className="text-center alert alert-success" role="alert">
                <b>{t("register.message_success_main")}</b>
                <p>
                  <p />
                  {t("register.message_success_start")}
                  <Link to={"/login"} className="btn-link">
                    {t("main.login")}
                  </Link>
                  {t("register.message_success_end")}
                </p>
              </div>
            </div>
          ) : (
            <div>
              <div className="form-group">
                <label htmlFor="username">{t("register.email")}</label>
                <Input
                  type="text"
                  className="form-control"
                  name="username"
                  value={username}
                  onChange={onChangeUsername}
                  validations={[required, email]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="password">{t("register.password")}</label>
                <Input
                  type="password"
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={onChangePassword}
                  validations={[required, vpassword]}
                />

                <div className="notes">
                  <div>{t("password.password_info_1")}</div>
                  <div>{t("password.password_info_2")}</div>
                  <div>{t("password.password_info_3")}</div>
                  <div>{t("password.password_info_4")}</div>
                  <div>{t("password.password_info_5")}</div>
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="firstName">{t("register.name_first")}</label>
                <Input
                  type="text"
                  className="form-control"
                  name="firstName"
                  value={firstName}
                  onChange={onChangeFirstName}
                  validations={[vusername]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="lastName">{t("register.name_last")}</label>
                <Input
                  type="text"
                  className="form-control"
                  name="lastName"
                  value={lastName}
                  onChange={onChangeLastName}
                  validations={[vusername]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="language">{t("register.language")}</label>
                <Select
                  type="text"
                  options={global.config.languages}
                  name="language"
                  value={language}
                  onChange={onChangeLanguage}
                  // isDisabled
                />
              </div>

              <div className="form-group">
                <button className="btn btn-primary btn-block">
                  {t("register.button_sign_up")}
                </button>
              </div>
            </div>
          )}

          {error && (
            <div className="form-group">
              <ErrorHandler error={error} header={false} />
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </div>
  );
};

export default Register;
