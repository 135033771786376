import React, { useState, useRef, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import Select from "react-select";
import LocalStorageService from "../services/local-storage.service";
import UserService from "../services/user.service";
import { useTranslation } from "react-i18next";
import { isEmail } from "validator";
import Moment from "moment";
import ErrorHandler from "../components/error-handler.component";

const Profile = (props) => {
  const form = useRef();
  const checkBtn = useRef();
  const { t, i18n } = useTranslation();

  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [avatar] = useState("");
  const [language, setLanguage] = useState(
    global.config.languages.find(
      (item) =>
        item.value ===
        LocalStorageService.userLanguage().toString().toUpperCase()
    )
  );
  const [regDate, setRegDate] = useState("");
  const [payDate, setPayDate] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    UserService.getUser().then(
      (response) => {
        setUsername(response.username);
        setFirstName(response.firstName);
        setLastName(response.lastName);
        setLanguage(
          global.config.languages.find(
            (item) => item.value === response.lng.toString().toUpperCase()
          )
        );
        setRegDate(response.regDate);
        setPayDate(response.payDate);
        setDone(false);
        setError("");
      },
      (error) => {
        setDone(false);
        setError(error.response);
      }
    );
  }, []);

  const required = (value) => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          {t("error.field_required")}
        </div>
      );
    }
  };

  const email = (value) => {
    if (!isEmail(value)) {
      return (
        <div className="alert alert-danger" role="alert">
          {t("error.field_invalid_email")}
        </div>
      );
    }
  };

  const vusername = (value) => {
    if (value.length < 3 || value.length > 20) {
      return (
        <div className="alert alert-danger" role="alert">
          {t("error.field_username_length")}
        </div>
      );
    }
  };

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
    setHasChanges(true);
  };

  const onChangeFirstName = (e) => {
    const firstName = e.target.value;
    setFirstName(firstName);
    setHasChanges(true);
  };

  const onChangeLastName = (e) => {
    const lastName = e.target.value;
    setLastName(lastName);
    setHasChanges(true);
  };

  const onChangeLanguage = (e) => {
    const language = e;
    setLanguage(language);
    setHasChanges(true);
  };

  const onChangeDisabled = () => {
    setIsDisabled(!isDisabled);
    setDone(false);
    setError("");
    setHasChanges(false);
  };

  const onChangeCancel = () => {
    setIsDisabled(!isDisabled);
    setUsername(LocalStorageService.currentUser().username);
    setFirstName(LocalStorageService.currentUser().firstName);
    setLastName(LocalStorageService.currentUser().lastName);
    setLanguage(
      global.config.languages.find(
        (item) =>
          item.value ===
          LocalStorageService.currentUser().lng.toString().toUpperCase()
      )
    );
    setHasChanges(false);
    setDone(false);
    setError("");
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    setDone(false);
    setError("");

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      UserService.updateUser(
        username,
        firstName,
        lastName,
        avatar,
        language.value
      ).then(
        (response) => {
          i18n.changeLanguage(
            LocalStorageService.userLanguage().toString().toLowerCase()
          );

          setUsername(response.username);
          setFirstName(response.firstName);
          setLastName(response.lastName);
          setLanguage(
            global.config.languages.find(
              (item) => item.value === response.lng.toString().toUpperCase()
            )
          );
          setRegDate(response.regDate);
          setPayDate(response.payDate);
          setIsDisabled(true);
          setHasChanges(false);
          setDone(true);
          setError("");
        },
        (error) => {
          setError(error.response);
          setDone(true);
          setIsDisabled(true);
          setHasChanges(false);
          setUsername(LocalStorageService.currentUser().username);
          setFirstName(LocalStorageService.currentUser().firstName);
          setLastName(LocalStorageService.currentUser().lastName);
          setLanguage(
            global.config.languages.find(
              (item) =>
                item.value ===
                LocalStorageService.currentUser().lng.toString().toUpperCase()
            )
          );
        }
      );
    }
  };

  if (!LocalStorageService.currentUser()) {
    return <Redirect to="/login" />;
  }

  return (
    <div className="col-md-12">
      <div className="card card-container">
        <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        />

        <Form onSubmit={handleUpdate} ref={form}>
          <div>
            <div className="form-group">
              <label htmlFor="username">{t("profile.email")}</label>
              <Input
                type="text"
                className="form-control"
                name="username"
                value={username}
                onChange={onChangeUsername}
                validations={[required, email]}
                disabled={isDisabled}
              />
            </div>
            <div className="form-group">
              <label htmlFor="firstName">{t("profile.name_first")}</label>
              <Input
                type="text"
                className="form-control"
                name="firstName"
                value={firstName}
                onChange={onChangeFirstName}
                validations={[vusername]}
                disabled={isDisabled}
              />
            </div>
            <div className="form-group">
              <label htmlFor="lastName">{t("profile.name_last")}</label>
              <Input
                type="text"
                className="form-control"
                name="lastName"
                value={lastName}
                onChange={onChangeLastName}
                validations={[vusername]}
                disabled={isDisabled}
              />
            </div>
            <div className="form-group">
              <label htmlFor="language">{t("profile.language")}</label>
              <Select
                type="text"
                options={global.config.languages}
                name="language"
                value={language}
                onChange={onChangeLanguage}
                isDisabled={isDisabled}
              />
            </div>
            <div className="form-group">
              <label htmlFor="regDate">{t("profile.date_registration")}</label>
              <Input
                type="text"
                className="form-control"
                name="regDate"
                value={regDate ? Moment(regDate).format("DD.MM.YYYY") : null}
                disabled={true}
              />
            </div>
            <div className="form-group">
              {payDate ? (
                <div>
                  <label htmlFor="payDate">{t("profile.date_purchase")}</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="payDate"
                    value={
                      payDate ? Moment(payDate).format("DD.MM.YYYY") : null
                    }
                    disabled={true}
                  />
                </div>
              ) : (
                <Link
                  id="purchase"
                  to={"/purchase"}
                  className="btn btn-success btn-block disabled"
                >
                  {t("profile.button_purchase")}
                </Link>
              )}
            </div>

            <div className="form-group">
              {isDisabled ? (
                <div>
                  <a
                    href={() => false}
                    className="btn btn-primary btn-block"
                    onClick={onChangeDisabled}
                  >
                    {t("profile.button_edit")}
                  </a>
                </div>
              ) : (
                <div>
                  <a
                    href={() => false}
                    className="btn btn-secondary btn-block"
                    onClick={onChangeCancel}
                  >
                    {t("profile.button_cancel")}
                  </a>
                  <button
                    className="btn btn-warning btn-block"
                    disabled={!hasChanges}
                  >
                    {t("profile.button_update")}
                  </button>
                </div>
              )}
            </div>
          </div>

          {done && (
            <div className="form-group">
              {error ? (
                <ErrorHandler error={error} header={false} />
              ) : (
                <div className="text-center alert alert-success" role="alert">
                  {t("profile.message_success")}
                </div>
              )}
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </div>
  );
};

export default Profile;
